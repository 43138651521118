import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";

const getTransaction = gql`
  query GetTransaction($id: String!) {
    transaction(id: $id) {
      __typename
      ... on LegacyTransaction {
        id
        type
        integrator
        account {
          accountId
          username
          fullName
          email
        }
        date
        currencyCode
        amount
        usdAmount
        invoiceId
        invoiceType
        invoiceReferenceType
        invoiceReferenceId
        purchaseStatusCode
        escrowId
        escrowPurchaseStatusCode
        escrowInvoiceId
        commitInvoiceId
        cancelInvoiceId
        balanceTransferId
        balanceTransferType
        balanceTransferStatusCode
        balanceTransferReferenceType
        balanceTransferReferenceId
        balanceTransferMetadata
        parties {
          role
          amount
          currencyCode
          usdAmount
          account {
            accountId
            username
          }
          payments {
            paymentId
            provider
            processingAmount
            processingCurrency
            paymentMethod {
              id
              displayString
              provider
            }
          }
          lineItems {
            lineItemId
            description
            referenceId
            productSku
            currency
            amount
          }
        }
        summarySubtotalAmount
        summarySubtotalCurrency
        summaryTaxAmount
        summaryTaxCurrency
        payments {
          paymentId
          provider
          externalLink
          processingAmount
          processingCurrency
          paymentMethod {
            id
            accountId
            displayString
            fullName
            provider
            providerEmail
            providerVerified
          }
        }
        lineItems {
          lineItemId
          productSku
          productCode
          amount
          currency
          displayAmount
          referenceType
          referenceId
          transactionType
          description
          parties {
            role
            amount
            currencyCode
            usdAmount
            account {
              accountId
              fullName
              username
            }
          }
        }
        subItems {
          subItemId
          amount
          currency
          subItemType
          sourceAccount {
            accountId
            fullName
            username
          }
          sourceWallet {
            id
            description
          }
          destinationAccount {
            accountId
            fullName
            username
          }
          destinationWallet {
            id
            description
          }
        }
      }
      ... on Payout {
        id
        type
        integrator
        account {
          accountId
          username
          fullName
          email
        }
        date
        updatedDate
        amount
        currencyCode
        payoutStatusId
        statusCode
        failureReason
        tags
        autoPayoutDecision {
          decisionType
          passReasons
          failReasons
          reviewReasons
        }
        notes {
          id
          subject
          body
        }
        credit {
          id
          integrator
          amount
          currencyCode
          feeAmount
          statusCode
          destinationPaymentMethod {
            id
            accountId
            methodClass
            displayString
            fullName
            provider
            providerEmail
            providerVerified
            pspReference
            pspHashCode
            processingCurrency
            pmState
            integrator
          }
        }
      }
      ... on TokenPurchase {
        id
        type
        integrator
        account {
          accountId
          username
          fullName
          email
        }
        date
        amount
        currencyCode
        tokenPurchaseId
        tokenPurchaseStatusCode
        payment {
          paymentId
          provider
          externalLink
          processingAmount
          processingCurrency
          paymentMethod {
            id
            displayString
            provider
          }
        }
        exchangeRate
        tokenAmount
        tokenCurrencyCode
        destinationWallet {
          description
        }
        amountMinusFees
        integratorFeeAmount
        tiliaFeeAmount
        feeCurrencyCode
        invoiceId
        tags
      }
      ... on TokenConversion {
        id
        type
        integrator
        account {
          accountId
          username
          fullName
          email
        }
        date
        amount
        currencyCode
        tokenConversionId
        tokenConversionStatusCode
        exchangeRate
        convertedAmount
        convertedCurrencyCode
        destinationWallet {
          id
          description
        }
        amountMinusFees
        integratorFeeAmount
        tiliaFeeAmount
        feeCurrencyCode
        invoiceId
        tags
      }
    }
  }
`;

const useGetTransaction = (options) => {
  return useQuery(getTransaction, options);
};

export default useGetTransaction;
