import { useState } from "react";
import { useInterval } from "usehooks-ts";
import { useColorMode } from "@chakra-ui/react";
import { LogoutIcon, ThemeIcon, AgentCogIcon } from "components/Icons";
import { MenuButton } from "components/Menu";
import { useAgent } from "context/agentContext";
import * as R from "ramda";
import { useNavigate } from "react-router-dom";
import "styled-components/macro";
import { LIGHT, useTheme } from "styles";
import { useChangePasswordModal } from "./useChangePasswordModal";
import { useReloginModal } from "./useReloginModal";
import { formatDiffDuration, noop } from "utils";

export const AgentMenu = () => {
  const { toggleTheme, currentTheme } = useTheme();
  const { setColorMode } = useColorMode();

  const navigate = useNavigate();
  const { agent, setAgentSetting, agentSettings, logoutAgent } = useAgent();

  const showChangePassword = agent.integratorConfig.ownsPassword;
  const showDebugging = agentSettings.debugMode;

  const [ChangePasswordModal, changePasswordMenuItem] = useChangePasswordModal();

  const [ReloginModal] = useReloginModal();

  const loginPath = `/login/${agent.integrator}`;

  const handleLogout = () => {
    logoutAgent();
    navigate(loginPath);
  };

  const handleRelogin = () => {
    logoutAgent();
    navigate(`${loginPath}?r=${encodeURIComponent(window.location.href)}`);
  };

  const handleTheme = () => {
    const newThemeName = toggleTheme();
    setColorMode(newThemeName.toLowerCase());
    setAgentSetting("theme", newThemeName);
  };
  const handleDiagnostics = () => navigate("/diagnostics");

  const finalItems = R.filter(R.identity, [
    showChangePassword
      ? { ...changePasswordMenuItem, "data-testid": "agent-menu-item-change-password" }
      : undefined,
    {
      label: `Theme - ${currentTheme._name === LIGHT ? "Go Dark" : "Go Light"}`,
      icon: <ThemeIcon size="1rem" />,
      onSelect: handleTheme,
      "data-testid": "agent-menu-item-theme",
    },
    {
      label: "Diagnostics",
      icon: <AgentCogIcon size="1rem" />,
      onSelect: handleDiagnostics,
      "data-testid": "agent-menu-item-diagnostics",
    },
    showDebugging
      ? {
          label: "ID: " + agent.account_id,
          onSelect: handleDiagnostics,
        }
      : undefined,
    showDebugging
      ? {
          label1: `EXP: ${formatDiffDuration(agent.tokenExpiration)} / ${agent.tokenExpiration}`,
          label: (
            <div>
              <LiveExpiration expiration={agent.tokenExpiration} />
            </div>
          ),
          onSelect: handleDiagnostics,
        }
      : undefined,
    {
      label: "Logout",
      icon: <LogoutIcon size="1rem" />,
      onSelect: handleLogout,
      "data-testid": "agent-menu-item-logout",
    },
  ]);

  const buttonLabel = `${agent?.integratorConfig?.displayName} / ${agent?.username}`;

  return (
    <>
      <MenuButton data-testid="agent-menu-button" buttonLabel={buttonLabel} items={finalItems} />
      <ChangePasswordModal onSuccess={noop} />
      <ReloginModal onAccept={handleRelogin} />
    </>
  );
};

const LiveExpiration = ({ expiration }) => {
  const [diff, setDiff] = useState(formatDiffDuration(expiration));

  useInterval(() => {
    setDiff(formatDiffDuration(expiration));
  }, 1000);

  return `EXP: ${diff} / ${expiration}`;
};
