import React, { useEffect, useState } from "react";
import * as R from "ramda";
import { lighten, darken } from "polished";
import { ThemeProvider as StyledProvider, StyleSheetManager } from "styled-components";
import isPropValid from "@emotion/is-prop-valid";
import { useAgent } from "context/agentContext";
import { isNilOrEmpty, ENVIRONMENT, getEnvironment } from "utils";
import {
  defineStyleConfig,
  ChakraProvider,
  extendTheme,
  theme as baseTheme,
} from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";
import cardTheme from "./card";
import accordionTheme from "./accordion";
import tabsTheme from "./tabs";

export const LIGHT = "Light";
export const DARK = "Dark";

const tiliaPayDarkBlue = "#004D99";
const tiliaPayLightBlue = "#2E8AE6";
const tiliaPayGreen = "#39B31B";
const tiliaPayYellow = "#F2CB0C";
const tiliaPayRed = "#E64F45";
const tiliaGray800 = "#1B1D21";

const titleBgMap = {
  [ENVIRONMENT.DEV]: tiliaPayGreen,
  [ENVIRONMENT.STAGING_TEST]: tiliaPayYellow,
  [ENVIRONMENT.STAGING]: tiliaPayRed,
};

const getTitleBg = () => R.defaultTo(tiliaPayLightBlue, titleBgMap[getEnvironment()]);

export const lightTheme = {
  _name: LIGHT,
  pageHeaderHeight: "60px",
  sidenavWidth: "240px",
  sidenavWidthCollapsed: "54px",
  sidenavIconFg: "#444",
  forms: {
    borderRadius: "2px",
    outline: "1px dotted #ccc",
  },
  colors: {
    _debug: "red",
    mask: "transparent",
    logoCircle: {
      bg: tiliaPayLightBlue,
      color: "#fff",
    },
    brand: {
      500: tiliaPayLightBlue,
      700: tiliaPayDarkBlue,
    },
    appHeaderBg: "#fff",
    appHeaderBoxShadow: "rgba(0, 0, 0, 0.03)",
    appTitleBg: getTitleBg(),
    alertOFF: "#CC0000",
    alert: "#fb1723",
    alertBg: "rgba(201,0,0,0.1)",
    contentBg: "#f6f6f6",
    button: {
      primary: {
        bg: tiliaPayLightBlue,
        border: tiliaPayLightBlue,
        color: "#fff",
        hover: {
          bg: darken(0.06, tiliaPayLightBlue),
          border: darken(0.06, tiliaPayLightBlue),
        },
      },
      outline: {
        bg: "transparent",
        border: "#ccc",
        hover: {
          bg: "rgba(172,176,181,.1)",
        },
      },
      icon: {
        bg: "inherit",
        color: "inherit",
        hover: {
          bg: "rgba(172,176,181,.1)",
        },
        active: {
          bg: "rgba(172,176,181,.1)",
        },
      },
      text: {
        bg: "transparent",
        border: "transparent",
        hover: {
          bg: "rgba(172,176,181,.1)",
        },
      },
      disabled: {
        bg: "transparent",
        border: "#ccc",
        color: "#999",
      },
      danger: {
        bg: "#c10707",
        border: "#c10707",
        color: "#fff",
        hover: {
          bg: darken(0.06, "#c10707"),
          border: darken(0.08, "#c10707"),
        },
      },
      error: {
        bg: "#c10707",
        border: "#c10707",
        color: "#fff",
        hover: {
          bg: darken(0.06, "#c10707"),
          border: darken(0.08, "#c10707"),
        },
      },
    },
    darkBg: "#282c34",
    darkHoverBg: lighten(0.04, "#282c34"),
    darkActiveBg: lighten(0.06, "#282c34"),
    dataTable: {
      border: "#dddddd",
      boxShadow: "#eaeaea",
      rowBorder: "#e6e6e6",
      cellBg: "#fcfcfc",
      expandedBg: "#fefefe",
      hoverBg: "#f1f1f1",
      hoverFg: "currentColor",
    },
    error: "#CC0000",
    focus: "#ccc",
    fontColor: "#333333",
    idColor: "#aaa",
    fontInverse: "#f6f6f6",
    forms: {
      border: "#cccccc",
      color: "currentColor",
      disabled: {
        border: "#eee",
        color: "#eee",
        bg: "#eee",
      },
    },
    highlight: {
      bg: tiliaPayLightBlue,
      color: "#fcfcfc",
    },
    iconLink: {
      base: "#333",
      hover: "#999",
      active: "blue",
    },
    link: {
      base: tiliaPayDarkBlue,
    },
    logo: {
      bg: "transparent",
    },
    menu: {
      bg: "#ffffff",
      border: "#f0f0f0",
      disabled: "#999",
      fg: "#000",
      list: {
        bg: "#ffffff",
      },
      selectedBg: "#f6f6f6",
      focusedBg: "#f0f0f0",
    },
    modal: {
      bg: "#fcfcfc",
      fg: "currentColor",
      border: "#ccc",
      columnHeader: "#999",
    },
    pageBg: "#fcfcfc",
    pageFg: "#000",
    panelBorder: "#fcfcfc",
    panelBg: "#fcfcfc",
    panelTitleBorder: "#f0f0f0",
    panelBoxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
    sorter: {
      hoverBg: "#f6f6f6",
      indicatorColor: "#ddd",
    },
    subNav: {
      activeBorder: "#304655",
      activeFg: "#000",
      hoverActiveBorder: "#304655",
      hoverActiveFg: "#304655",
      hoverInactiveBorder: "#aaa",
      hoverInactiveFg: "#333",
      inactiveBorder: "transparent",
      inactiveFg: "#aaa",
      rowBorder: "#eee",
    },
    success: tiliaPayGreen,
    tilia: {
      buttonBg: "#d8eeef",
      buttonBorder: "#12c4ca",
    },
    warning: {
      color: "red",
      bg: "#eee",
    },
    textArea: {
      inactive: {
        color: "#999",
        bg: "#eee",
      },
    },
  },
};

const darkLineColor = "#34373A";

export const darkTheme = R.mergeDeepRight(lightTheme, {
  _name: DARK,
  colors: {
    mask: "transparent",
    appHeaderBg: "#282c34",
    appHeaderBoxShadow: "rgba(255, 255, 255, 0.03)",
    button: {
      primary: {
        color: "#000",
      },
      outline: {
        color: "currentColor",
        //     bg: "transparent",
        border: darkLineColor,
        //     hover: {
        //       bg: "rgba(172,176,181,.1)"
        //     }
      },
      icon: {
        bg: "inherit",
        color: "inherit",
        hover: {
          bg: "rgba(172,176,181,.1)",
        },
        active: {
          bg: "rgba(172,176,181,.1)",
        },
      },
      text: {
        color: "currentColor",
        bg: "transparent",
        border: "transparent",
        hover: {
          bg: "rgba(172,176,181,.1)",
        },
      },
      disabled: {
        bg: "transparent",
        border: darkLineColor,
        color: "#585858",
      },
      //   error: {
      //     bg: "#c10707",
      //     border: "#c10707",
      //     color: "#fff",
      //     hover: {
      //       bg: darken(0.06, "#c10707"),
      //       border: darken(0.08, "#c10707")
      //     }
      //   }
    },
    darkBg: "#111",
    dataTable: {
      border: darkLineColor,
      boxShadow: "#0c0c0c",
      cellBg: tiliaGray800,
      expandedBg: "#212529",
      hoverBg: "#212529",
      hoverFg: "currentColor",
      rowBorder: darkLineColor,
    },
    focus: darkLineColor,
    fontInverse: "#d1d2d3",
    forms: {
      border: darkLineColor,
      color: "#d1d2d3",
      bg: "#212529",
      multi: {
        bg: "var(--chakra-colors-gray-600)",
      },
      disabled: {
        border: "#999",
        color: "#999",
        bg: "transparent",
      },
    },
    highlight: {
      bg: tiliaPayLightBlue,
      color: "currentColor",
    },
    link: {
      base: tiliaPayLightBlue,
    },

    menu: {
      bg: "#212529",
      border: darkLineColor,
      disabled: "#999",
      fg: "#D1D2D3",
      list: {
        bg: "#212529",
      },
      selectedBg: lighten(0.04, "#282c34"),
      focusedBg: lighten(0.1, "#282c34"),
    },
    modal: {
      bg: tiliaGray800,
      fg: "#D1D2D3",
      border: darkLineColor,
    },
    pageBg: tiliaGray800,
    pageFg: "#D1D2D3",
    panelBorder: "transparent",
    panelBg: tiliaGray800,
    panelBoxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.5)",
    sorter: {
      hoverBg: "#212529",
      indicatorColor: "#red",
    },
    subNav: {
      activeBorder: "#fff",
      activeFg: "#fff",
      hoverActiveBorder: "#fff",
      hoverActiveFg: "#fff",
      hoverInactiveBorder: "#aaa",
      hoverInactiveFg: "#eee",
      inactiveBorder: "transparent",
      inactiveFg: "#aaa",
      rowBorder: darkLineColor,
    },
    warning: {
      color: "red",
    },
    textArea: {
      inactive: {
        color: "#969696",
        bg: "#212529",
      },
    },
  },
});

const map = {
  [LIGHT]: lightTheme,
  [DARK]: darkTheme,
};
const defaultTheme = map[LIGHT];

const mapToChakra = (currentTheme) => {
  return {
    initialColorMode: currentTheme._name.toLowerCase(),
    useSystemColorMode: false,
    colors: {
      brand: {
        200: baseTheme.colors.blue[200],
        500: tiliaPayLightBlue,
        700: tiliaPayDarkBlue,
      },
      gray: {
        800: tiliaGray800,
      },
    },
    sizes: {
      sidenavWidth: currentTheme.sidenavWidth,
      sidenavWidthCollapsed: currentTheme.sidenavWidthCollapsed,
    },
    components: {
      Accordion: accordionTheme,
      Card: cardTheme,
      Tabs: tabsTheme,
      Stat: {
        variants: {
          wallet: () => ({
            label: {
              m: 0,
              fontSize: 12,
            },
            number: {
              fontWeight: 500,
              fontSize: 24,
              m: 0,
            },
          }),
          brand: (props) => ({
            label: {
              m: 0,
            },
            number: {
              fontSize: "md",
              fontWeight: "bold",
              color: mode("brand.700", "brand.200")(props),
              m: 0,
            },
          }),
        },
      },
      Button: {
        variants: {
          card: (props) => {
            const styles = baseTheme.components.Button.variants.ghost(props);
            return {
              ...styles,
              py: 3,
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: mode("transparent", "whiteAlpha.500")(props),
              borderRadius: 8,
              shadow: "md",
              color: "inherit",
              height: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "normal",
            };
          },
          menu: {
            color: currentTheme.colors.menu.fg,
            backgroundColor: "transparent",
            borderColor: "transparent",
            borderRadius: "3px",
            fontWeight: "normal",
            textTransform: "uppercase",
            _hover: {
              backgroundColor: currentTheme.colors.button.outline.hover.bg,
            },
            _active: {
              backgroundColor: currentTheme.colors.button.outline.hover.bg,
            },
          },
        },
      },

      ChartTooltip: {
        baseStyle: (props) => ({
          backgroundColor: mode("whiteAlpha.900", "gray.800")(props),
          py: 4,
          px: 6,
          border: "1px solid",
          borderRadius: 4,
          borderColor: mode("gray.200", "whiteAlpha.500")(props),
        }),
      },
      DetailText: defineStyleConfig({
        baseStyle: (props) => ({
          color: mode("gray.600", "gray.200")(props),
          fontSize: "xs",
        }),
      }),
      Menu: {
        parts: ["list", "item"],
        baseStyle: {
          list: {
            backgroundColor: currentTheme.colors.menu.list.bg,
            color: currentTheme.colors.menu.fg,
            borderColor: "transparent",
            borderRadius: "6px",
          },
          item: {
            borderColor: "transparent",
            backgroundColor: currentTheme.colors.menu.bg,
            _hover: {
              backgroundColor: "rgba(172,176,181,.1)",
            },
            _focus: {
              color: currentTheme.colors.menu.fg,
              backgroundColor: "rgba(172,176,181,.1)",
            },
          },
        },
        defaultProps: {
          size: "md",
        },
      },
      Modal: {
        baseStyle: {
          body: {
            overflow: "auto",
          },
        },
      },
      SectionHeading: defineStyleConfig({
        baseStyle: () => ({
          mb: 2,
          mt: 0,
          color: "gray.600",
          textTransform: "uppercase",
          _dark: {
            color: "gray.200",
          },
        }),
      }),
      Table: {
        baseStyle: (props) => {
          return {
            tfoot: {
              tr: {
                td: {
                  fontWeight: "bold",
                  color: mode("gray.600", "gray.400")(props),
                },
                "td:first-of-type": {
                  paddingLeft: 0,
                },
                "td:last-child": {
                  paddingRight: 0,
                },
              },
            },
            tbody: {
              tr: {
                "td:first-of-type": {
                  paddingLeft: 0,
                },
                "td:last-child": {
                  paddingRight: 0,
                },
              },
            },
            thead: {
              tr: {
                "th:first-of-type": {
                  paddingLeft: 0,
                },
                "th:last-child": {
                  paddingRight: 0,
                },
              },
              "& ~ thead": {
                "tr:first-of-type": {
                  th: {
                    paddingTop: 5,
                  },
                },
              },
            },
          };
        },
      },
    },
  };
};

const getTheme = (agentSettings) => {
  if (isNilOrEmpty(agentSettings)) return defaultTheme;
  return agentSettings.theme ? map[agentSettings.theme] : defaultTheme;
};

const ThemeToggle = React.createContext();

export const useTheme = () => React.useContext(ThemeToggle);

export const ThemeProvider = ({ children }) => {
  const [currentTheme, setTheme] = useState(defaultTheme);
  const { agentSettings } = useAgent();

  useEffect(() => {
    setTheme(getTheme(agentSettings));
  }, [agentSettings]);

  const toggleTheme = () => {
    const newTheme = currentTheme._name === LIGHT ? darkTheme : lightTheme;
    setTheme(newTheme);
    return newTheme._name;
  };
  const chakraTheme = extendTheme(mapToChakra(currentTheme));

  return (
    <ThemeToggle.Provider value={{ toggleTheme, currentTheme }}>
      <StyledProvider theme={currentTheme}>
        <StyleSheetManager
          shouldForwardProp={(propName, target) =>
            typeof target === "string" ? isPropValid(propName) : true
          }
        >
          <ChakraProvider resetCSS={false} theme={chakraTheme}>
            {children}
          </ChakraProvider>
        </StyleSheetManager>
      </StyledProvider>
    </ThemeToggle.Provider>
  );
};
