import { Adyen, Ingenico, Paypal, Rebilly, Worldpay } from "components/Logos";
import FormattedAddress from "./FormattedAddress";
import { formatDate } from "utils";

const PROVIDER_MAP = {
  adyen: <Adyen />,
  paypal: <Paypal />,
  rebilly: <Rebilly />,
  worldpay: <Worldpay />,
  ingenico: <Ingenico />,
};

const formatExpirationDate = (dateString) => {
  const expirationRegex = /\d{4}/;
  if (expirationRegex.test(dateString)) {
    return `${dateString.substring(0, 2)}/${dateString.substring(2)}`;
  }
  return dateString || "-";
};

const getMaskedCardNumber = (bin, lastFour) => {
  if (!bin && !lastFour) {
    return "-";
  } else {
    return `${bin || ""}******${lastFour || ""}`;
  }
};

export function getPaymentMethodTitle(method) {
  let title;
  switch (method.method_class) {
    case "paypal":
      title = method.psp_provided_email;
      break;
    default:
      title = method.display_string;
  }
  return title;
}

export function getPaymentMethodDetails(method) {
  let methodClassRows = [
    { title: "Name", detail: method.full_name || "-" },
    { title: "Address", detail: <FormattedAddress data={method} /> },
  ];

  switch (method.method_class) {
    case "paypal":
      methodClassRows.push({
        title: "Provided Email",
        detail: method.psp_provided_email || "-",
      });
      break;
    default:
      const rows = [
        {
          title: "Card Number",
          detail: getMaskedCardNumber(method.bin, method.last_four),
        },
        { title: "Card Expiration", detail: formatExpirationDate(method.expiration) },
        { title: "Provider", detail: PROVIDER_MAP[method.provider] || method.provider || "-" },
      ];
      methodClassRows = methodClassRows.concat(rows);
  }

  methodClassRows = methodClassRows.concat([
    { title: "Created", detail: method.created ? formatDate(method.created) : "-" },
  ]);
  return methodClassRows;
}
