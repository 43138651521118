import { PermissionsMessage } from "components/PermissionsMessage";
import { SectionSpinner } from "components/Spinner";
import { useAccount } from "context/accountContext";
import { useAlert } from "context/alertContext";
import * as R from "ramda";
import { useEffect } from "react";
import "styled-components/macro";
import { STATUS } from "utils";
import { Payments } from "./Payments";
import { Wallets } from "./Wallets";

const getPaymentMethods = R.path(["paymentMethods"]);
const getAccountId = R.path(["accountState", "details", "accountId"]);

const METHOD_TYPES = {
  WALLET: "WALLET",
  PAYMENT: "PAYMENT",
  CREDIT: "CREDIT",
};

const groupByType = R.groupBy((pm) =>
  R.propEq("wallet", "provider")(pm) ? METHOD_TYPES.WALLET : METHOD_TYPES.PAYMENT,
);

export const PaymentMethodList = ({ onSelectPayments }) => {
  const accountContext = useAccount();
  const accountId = getAccountId(accountContext);
  const paymentMethods = getPaymentMethods(accountContext);
  const { fireAlert } = useAlert();

  useEffect(() => {
    if (paymentMethods.status === STATUS.PRE_INIT) {
      paymentMethods.getPaymentMethods(accountId);
    }
  }, [accountId, paymentMethods]);

  if (paymentMethods.status === STATUS.PRE_INIT || paymentMethods.status === STATUS.INITIALIZED) {
    return <SectionSpinner />;
  }
  if (!paymentMethods.permissions) {
    return <PermissionsMessage />;
  }

  const { data } = paymentMethods;
  const methodsByType = groupByType(data || []);
  const onUpdate = () => {
    fireAlert("Payment method updated");
    paymentMethods.getPaymentMethods(accountId);
  };

  return (
    <>
      <Wallets walletPaymentMethods={methodsByType[METHOD_TYPES.WALLET]} />
      <Payments
        data={methodsByType[METHOD_TYPES.PAYMENT]}
        onUpdate={onUpdate}
        onSelectPayments={onSelectPayments}
      />
    </>
  );
};
