import { isNilOrEmpty } from "utils";

/**
 * Sorts the payment methods used in the transaction by wallets first, in
 * descending order by amount, then by all other payment methods in descending
 * order by amount. Returns an array of sorted payment method IDs.
 *
 * @param {Object<{payments: Array<{provider: string, processingAmount: number, paymentMethod: {id: string}}>}>} transaction
 * @returns {string[]}
 */
const getDefaultPaymentMethodOrder = (transaction) => {
  if (isNilOrEmpty(transaction.payments)) {
    return [];
  }

  let walletPayments = transaction.payments.filter((payment) => payment.provider === "wallet");

  walletPayments.sort((a, b) => b.processingAmount - a.processingAmount);

  // sort remaining payment methods by amount greatest to least
  let otherPayments = transaction.payments.filter((payment) => payment.provider !== "wallet");

  otherPayments.sort((a, b) => b.processingAmount - a.processingAmount);

  walletPayments.push(...otherPayments);

  return walletPayments.map((payment) => payment.paymentMethod.id);
};

export default getDefaultPaymentMethodOrder;
