export default function FormattedAddress({ data }) {
  if (
    !(
      data.address1 ||
      data.address2 ||
      data.address3 ||
      data.city ||
      data.state ||
      data.country_iso ||
      data.zip
    )
  ) {
    return <>-</>;
  } else {
    return (
      <div data-testid="formatted-address">
        <div>{data.address1}</div>
        {data.address2 && <div>{data.address2}</div>}
        {data.address3 && <div>{data.address3}</div>}
        {data.city && (
          <div>
            {data.city}, {data.state} {data.country_iso} {data.zip}
          </div>
        )}
      </div>
    );
  }
}
