import { gql } from "@apollo/client";
import { useQuery } from "context/graphqlContext";
import { READ_INVOICES, TRANSACTIONS_RESOURCE } from "context/permissions";

const getAccountTransactions = gql`
  query GetAccountTransactions(
    $accountId: ID!
    $filter: AccountTransactionFilterInput
    $sort: TransactionFilterSort
    $first: NonNegativeInt
    $after: String
    $last: NonNegativeInt
    $before: String
  ) {
    account(accountId: $accountId) {
      accountId
      transactions(
        filter: $filter
        sort: $sort
        first: $first
        after: $after
        last: $last
        before: $before
      ) {
        edges {
          node {
            __typename
            ... on LegacyTransaction {
              id
              type
              integrator
              date
              amount
              currencyCode
              usdAmount
              invoiceId
              invoiceType
              invoiceReferenceType
              invoiceReferenceId
              purchaseStatusCode
              escrowId
              escrowPurchaseStatusCode
              balanceTransferId
              balanceTransferType
              balanceTransferStatusCode
              parties {
                role
                amount
                currencyCode
                usdAmount
                account {
                  accountId
                  username
                }
                payments {
                  paymentId
                  provider
                  processingAmount
                  processingCurrency
                  paymentMethod {
                    id
                    displayString
                    provider
                  }
                }
                lineItems {
                  lineItemId
                  description
                  referenceId
                  productSku
                  currency
                  amount
                }
              }
              refundSummary {
                status
                totalAmount
                currency
              }
              payments {
                paymentId
                provider
                processingAmount
                processingCurrency
                paymentMethod {
                  id
                  displayString
                  provider
                }
              }
              lineItems {
                lineItemId
                description
                referenceId
                productSku
                currency
                amount
                refundSummary {
                  status
                  totalAmount
                  currency
                }
                tags
              }
              tags
            }
            ... on Payout {
              id
              type
              integrator
              date
              amount
              currencyCode
              payoutStatusId
              statusCode
              invoiceType
              credit {
                id
                integrator
                amount
                currencyCode
                feeAmount
                statusCode
                referenceId
                sourcePaymentMethod {
                  id
                  displayString
                  provider
                }
                destinationPaymentMethod {
                  id
                  displayString
                  provider
                }
              }
            }
            ... on TokenPurchase {
              id
              type
              integrator
              date
              amount
              currencyCode
              tokenPurchaseId
              tokenPurchaseStatusCode
              payment {
                paymentMethod {
                  id
                  displayString
                  provider
                }
              }
              tokenAmount
              tokenCurrencyCode
              destinationWallet {
                description
              }
              amountMinusFees
              integratorFeeAmount
              tiliaFeeAmount
              feeCurrencyCode
              invoiceId
              refundSummary {
                status
              }
              tags
            }
            ... on TokenConversion {
              id
              type
              integrator
              date
              amount
              currencyCode
              tokenConversionId
              tokenConversionStatusCode
              convertedAmount
              convertedCurrencyCode
              destinationWallet {
                description
              }
              amountMinusFees
              integratorFeeAmount
              tiliaFeeAmount
              feeCurrencyCode
            }
          }
          cursor
        }

        pageInfo {
          hasPreviousPage
          hasNextPage
          startCursor
          endCursor
          offset
        }
        totalCount
      }
    }
  }
`;

/* errorPolicy: "all" reason:
 * this is so even if there is error at some field,
 * graphql will give us back the data instead of undefined
 */
const useAccountTransactions = ({ accountId, filter, sort, first, after, last, before }) => {
  return useQuery(getAccountTransactions, {
    permissions: [READ_INVOICES],
    resource: TRANSACTIONS_RESOURCE,
    errorPolicy: "all",
    variables: {
      accountId,
      filter: filter ?? undefined,
      sort: sort ?? undefined,
      first: first ?? undefined,
      after: after ?? undefined,
      last: last ?? undefined,
      before: before ?? undefined,
    },
  });
};

export default useAccountTransactions;
