import { debug } from "utils";
import {
  assertIntegrator,
  assertOk,
  assertOkWithMessage,
  mapProxyFetch,
  METHODS,
  serviceMap,
} from "./utils";

const paymentsProxy = mapProxyFetch(serviceMap.PAYMENTS_OPS_API);

export const getPaymentMethods = async (accountId, ctx) => {
  debug(ctx, "[getPaymentMethods]", accountId);
  assertIntegrator(ctx);
  const response = await paymentsProxy(ctx, `/${accountId}/payment_methods?state=all`);
  await assertOk(response);
  return response.json();
};

export const getPaymentMethod = async (pmid, ctx) => {
  debug(ctx, "[getPaymentMethod]", pmid);
  assertIntegrator(ctx);
  const response = await paymentsProxy(ctx, `/payment-method/${pmid}?state=all`);
  await assertOk(response);
  return response.json();
};

export const deactivatePaymentMethod = async (pmid, ctx) => {
  debug(ctx, "[deactivePaymentMethod]", pmid);
  assertIntegrator(ctx);
  const response = await paymentsProxy(ctx, `/payment-method/${pmid}`, METHODS.DELETE);
  await assertOkWithMessage(response);
  return response.json();
};
