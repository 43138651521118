import { Button, useDisclosure } from "@chakra-ui/react";
import * as Yup from "yup";
import { FormModal } from "components/Modal";
import { TextField, SelectField, SubmitCancelButtonGroup } from "components/Forms";
import { useAddPermissionGroup } from "api/account/useAddPermissionGroup";
import { useAgent } from "context/agentContext";
import { noop } from "utils";

const buildGroupOptions = (allGroups = []) =>
  allGroups.filter((g) => g !== "root").map((g) => ({ value: g, label: g }));

export const AddGroupModal = ({ allGroups, isOpen, onClose, onAddGroup = noop }) => {
  const { agent } = useAgent();
  const addPermissionGroup = useAddPermissionGroup();

  const handleSubmit = async ({ groupName, parentGroupName }, { setStatus }) => {
    return addPermissionGroup.mutate(
      { groupName, parentGroupName, agent },
      {
        onError: (error) => setStatus({ error: error.join(", ") }),
        onSuccess: () => onAddGroup(),
      }
    );
  };

  const groupsOptions = buildGroupOptions(allGroups);

  const validationSchema = Yup.object().shape({
    groupName: Yup.string()
      .label("Group name")
      .required("${label} is required") //eslint-disable-line no-template-curly-in-string
      .max(35)
      .matches(/^[a-z0-9_-]+$/, "${label} can only include lowercase letters, numbers, '_', or '-'") //eslint-disable-line no-template-curly-in-string
      .test("unique-group-name", "${label} must be unique", (value) => !allGroups.includes(value)), //eslint-disable-line no-template-curly-in-string
  });

  return (
    <FormModal
      resetOnOpen={true}
      actions={<SubmitCancelButtonGroup onCancel={onClose} />}
      formikProps={{
        initialValues: {
          groupName: "",
          parentGroupName: "",
        },
        validationSchema,
        onSubmit: handleSubmit,
      }}
      isOpen={isOpen}
      onClose={onClose}
      title="Add Group"
    >
      <TextField name="groupName" label="Group Name" required />
      <SelectField
        name="parentGroupName"
        label="Parent Group"
        options={groupsOptions}
        isClearable={true}
      />
    </FormModal>
  );
};

export const AddGroupModalButton = ({ onAddGroup, allGroups }) => {
  const { isOpen, onClose, getButtonProps } = useDisclosure();

  const onModalAddGroup = () => {
    onAddGroup();
    onClose();
  };

  return (
    <>
      <Button type="button" size="sm" {...getButtonProps()}>
        Add Group...
      </Button>
      <AddGroupModal
        allGroups={allGroups}
        isOpen={isOpen}
        onCancel={onClose}
        onClose={onClose}
        onAddGroup={onModalAddGroup}
      />
    </>
  );
};
