/*
 * NOTE: This file is duplicated at web/server/services/apis/buildTiliaApiResponse.js
 * because we currently do not have a way to share utility code between the src/ and
 * server/ roots due to incompatible ES Module loaders. Please keep any changes between
 * these two files in sync.
 */

/**
 * @typedef {Object} TiliaApiResponse
 * @template T
 * @property {number} status HTTP status code of the response
 * @property {T} payload Response payload of the response as a string or a deserialized JSON object
 * @property {any[]} errors Array of errors returned by the API call
 * @property {string[]} codes Array of string codes that are returned by the API call
 */

/**
 * Normalizes the Fetch Response object into the compact API response representation,
 * containing the HTTP status code, the response payload as either plain-text or a
 * deserialized JSON object, and an array of error message strings (if any).
 *
 * @param {Response} response Fetch response object
 * @returns {Promise<TiliaApiResponse<T>>}
 */
const buildTiliaApiResponse = async (response) => {
  let body = await response.text();
  let errors = [];
  const contentType = response.headers.get("Content-Type");
  if (contentType?.includes("application/json")) {
    try {
      body = JSON.parse(body);
      // the shape of error responses is inconsistent between APIs; attempt
      // to marshal all possibilities into a single array of errors
      if (body.status === "Failure") {
        if (Array.isArray(body.codes)) {
          errors = [...errors, ...body.codes];
        }
        if (Array.isArray(body.message)) {
          errors = [...errors, ...body.message];
        }
        if (body.payload) {
          if (typeof body.payload === "string") {
            errors.push(body.payload);
          } else if (body.payload?.errors) {
            errors.push(JSON.stringify(body.payload.errors));
          } else if (body.payload.error) {
            errors.push(body.payload.error);
          }
        }
      }
    } catch (e) {
      errors.push(`Unable to parse JSON response body: ${e.message}`);
    }
  }

  return {
    status: response.status,
    payload: body.payload || body,
    errors,
    codes: body.codes || [],
  };
};

export default buildTiliaApiResponse;
