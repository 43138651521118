/* eslint-disable no-useless-escape */
import React, { useEffect, useRef } from "react";
import { css } from "styled-components/macro";
import { MaskIcon, UnMaskIcon } from "components/Icons";
import { Field } from "formik";
import { theme } from "styles";
import { Label } from "../Label";
import { Error } from "../Error";

const inputCss = css`
  width: 100%;
  padding: 8px;
  border-radius: 2px;
  box-sizing: border-box;
  border: 1px solid ${theme("colors.forms.border")};
  background-color: ${theme("colors.forms.bg")};
  color: ${theme("colors.forms.color")};
`;

export const InfoField = ({ children, label }) => {
  return (
    <>
      <Label>{label}</Label>
      <div
        css={`
          ${inputCss};
          border: 1px solid transparent;
          background-color: transparent;
        `}
      >
        {children}
      </div>
    </>
  );
};

const ShowHideToggle = ({ onToggle, show }) => {
  return (
    <div
      role="button"
      css={`
        position: absolute;
        right: 24px;
        top: 6px;
        cursor: pointer;
      `}
      onClick={onToggle}
    >
      {show ? <MaskIcon /> : <UnMaskIcon />}
    </div>
  );
};

export const Text = ({
  disabled,
  error,
  focus,
  label,
  readOnly = false,
  required,
  showHide,
  touched,
  type = "text",
  ...props
}) => {
  const ref = useRef();

  // if showHide is true and type is 'text',
  // toggle type between password and text to mask the value
  const [show, setShow] = React.useState(false);
  const handleShowHidToggle = () => setShow((s) => !s);

  let finalType = type;
  if (type === "text" && showHide === true && show === false) {
    finalType = "password";
  }

  useEffect(() => {
    if (focus) {
      ref.current.focus();
    }
  }, [focus]);
  return (
    <div
      css={`
        margin-bottom: 4px;
      `}
    >
      <Label required={required} htmlFor={props.name}>
        {label}
      </Label>
      <div
        css={`
          position: relative;
        `}
      >
        <input
          alwaysShowMask={true}
          disabled={disabled || readOnly}
          ref={ref}
          type={finalType}
          id={props.name}
          data-testid={props.name}
          css={`
            ${inputCss}
            &[disabled] {
              color: ${readOnly ? "currentColor" : "#999"};
            }
            ${readOnly &&
            `
            border: 1px solid transparent;
            background-color: transparent;
          `}
            ${props.inputCssOverrides}
          `}
          {...props}
        />
        {showHide && <ShowHideToggle show={show} onToggle={handleShowHidToggle} />}
      </div>
      <Error error={error} touched={touched} />
    </div>
  );
};

const InputField = (props) => {
  return (
    <Field name={props.name}>
      {({ field, form: { errors, touched, ...formRest }, meta }) => {
        return (
          <Text {...field} error={meta.error} touched={meta.touched} {...formRest} {...props} />
        );
      }}
    </Field>
  );
};

export const TextField = (props) => <InputField type="text" {...props} />;
export const PasswordField = (props) => (
  <InputField type="password" autoComplete="current-password" {...props} />
);
