import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { CopyableText } from "components/Copyable";
import { Money } from "components/Money";
import { DetailText } from "components/Text";

function PaymentMethodDetail({ label, children }) {
  const gray600 = useColorModeValue("gray.600", "gray.200");

  return (
    <Flex flexDir={"column"} gap={"2px"}>
      <Text as="span" color={gray600} fontWeight={700}>
        {label}:
      </Text>
      <DetailText>{children || "-"}</DetailText>
    </Flex>
  );
}

function PaymentMethods({ transaction }) {
  // render all payment methods initially open
  const defaultIndex = Array.from(new Array(transaction.payments.length), (_, index) => index);
  return (
    <Accordion defaultIndex={defaultIndex} allowMultiple={true}>
      {transaction.payments.map((payment) => (
        <AccordionItem key={payment.paymentId}>
          <h3>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                {payment.paymentMethod.displayString}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h3>
          <AccordionPanel>
            <PaymentMethodDetail label="Amount">
              <Money value={payment.processingAmount} currency={payment.processingCurrency} />
            </PaymentMethodDetail>
            <PaymentMethodDetail label="Payment method ID">
              <CopyableText text={payment.paymentMethod.id}>
                {payment.paymentMethod.id}
              </CopyableText>
            </PaymentMethodDetail>
            {payment.externalLink && (
              <PaymentMethodDetail label="External link">
                <Link href={payment.externalLink} isExternal>
                  {payment.provider}
                </Link>
              </PaymentMethodDetail>
            )}
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
}

export default PaymentMethods;
