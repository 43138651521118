import { Box, SimpleGrid } from "@chakra-ui/react";
import { Panel, PanelContent, PanelTitle } from "components/Panel";
import * as R from "ramda";
import { getMillis } from "@tilia-tools/utils";
import PaymentCard from "./PaymentCard";
import { DataOrNo } from "./common";

const createdDesc = (a, b) => getMillis(b.created) - getMillis(a.created);

export const Payments = ({ data = [], onUpdate, onSelectPayments }) => {
  const paymentMethods = R.pipe(R.sort(createdDesc))(data);

  return (
    <>
      <Panel>
        <PanelTitle>Payment Methods ({data.length})</PanelTitle>
        <PanelContent>
          <DataOrNo data={data}>
            <Box maxW={"1600px"}>
              <SimpleGrid
                columns={{
                  base: 1,
                  lg: 2,
                  xl: 3,
                  "2xl": 4,
                }}
                spacing={8}
              >
                {paymentMethods.map((method) => (
                  <PaymentCard
                    key={method.id}
                    method={method}
                    onUpdate={onUpdate}
                    onSelectPayments={onSelectPayments}
                  />
                ))}
              </SimpleGrid>
            </Box>
          </DataOrNo>
        </PanelContent>
      </Panel>
    </>
  );
};
