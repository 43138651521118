import { Box, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import PaymentMethodLogo from "components/PaymentMethod/PaymentMethodLogo";
import { WRITE_PAYMENT_METHODS } from "context/permissions";
import useAgentHasPermissions from "context/useAgentHasPermissions";
import BinData from "./BinData";
import { useDeactivationModal } from "./DeactivationModal";
import { MethodCard } from "./MethodCard";
import { getPaymentMethodDetails, getPaymentMethodTitle } from "./getPaymentMethodDetails";
import useFeatureFlag from "utils/useFeatureFlag";

export default function PaymentCard({ method, onUpdate, onSelectPayments }) {
  const showPaymentsLink = useFeatureFlag("payment_method_payments");
  const [DeactivationModal, openModal] = useDeactivationModal(method);
  const deactivatable =
    useAgentHasPermissions([WRITE_PAYMENT_METHODS]) && method.pm_state === "ACTIVE";

  const mutedGray = useColorModeValue("gray.600", "gray.200");
  const { isOpen, onOpen: onOpenBinData, onClose: onCloseBinData } = useDisclosure();
  const title = (
    <Box fontSize={"sm"}>
      <PaymentMethodLogo methodClass={method.method_class} />
      <Box fontWeight={500} color={mutedGray} overflowWrap="anywhere">
        {getPaymentMethodTitle(method)}
      </Box>
    </Box>
  );

  const actionItems = [
    ...(deactivatable ? [{ label: "Deactivate", onClick: openModal }] : []),
    ...(method.bin
      ? [
          {
            label: "View BIN Data",
            onClick: onOpenBinData,
          },
        ]
      : []),
  ];
  if (showPaymentsLink) {
    actionItems.push({
      label: "Payments...",
      onClick: () => onSelectPayments(method.id),
    });
  }
  return (
    <MethodCard
      paymentId={method.id}
      title={title}
      verified={method.provider_data?.paypal_verified}
      badge={method.pm_state === "INACTIVE" ? { label: "Inactive", status: "critical" } : null}
      tags={method.tags?.map((tag) => ({
        label: tag.tag,
        status: tag.status,
      }))}
      detailList={getPaymentMethodDetails(method)}
      actionItems={actionItems}
    >
      {deactivatable && <DeactivationModal onSuccess={onUpdate} />}
      {method.bin && (
        <BinData title={title} method={method} isOpen={isOpen} onClose={onCloseBinData} />
      )}
    </MethodCard>
  );
}
